<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul
      v-if="!$route.meta.guestMode"
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <!-- Bookmarks Container -->

      <b-navbar-nav class="nav">
        <search-bar />
      </b-navbar-nav>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <help-dropdown />
      <contact-dropdown />
      <dark-Toggler class="d-none d-sm-block" />
      <!-- <search-bar /> -->
      <notification-dropdown v-show="false" />
      <!-- <user-dropdown /> -->

      <!-- Sidebar Toggler -->
      <button
        v-if="$route.meta.guestMode && !token"
        class="nav-link btn btn-primary"
        @click="$router.push({name: 'auth-login'})"
      >
        <feather-icon
          icon="LogInIcon"
          size="14"
        />
        <span class="d-none d-sm-inline"> Login</span>
      </button>
      <b-link
        v-else
        class="nav-link"
        @click="isUserPersonalSidebarOpen = !isUserPersonalSidebarOpen"
      >
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.name"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </b-link>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BAvatar } from 'bootstrap-vue'
import useUserPersonalSidebar from '@core/layouts/components/user-personal-sidebar/useUserPersonalSidebar'
import { avatarText } from '@core/utils/filter'
import SearchBar from './components/SearchBar.vue'
// import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
// import Locale from './components/Locale.vue'
// import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import HelpDropdown from './components/HelpDropdown.vue'
import ContactDropdown from './components/ContactDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    // Navbar Components
    // Bookmarks,
    DarkToggler,
    BLink,
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,
    SearchBar,
    BAvatar,
    HelpDropdown,
    ContactDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
      userData: JSON.parse(localStorage.getItem('userData')),
      token: localStorage.getItem('accessToken'),
    }
  },
  setup() {
    const { isUserPersonalSidebarOpen } = useUserPersonalSidebar()

    return {
      isUserPersonalSidebarOpen,
    }
  },
}
</script>

<style lang="scss">
.dark-layout {
  .sidebar-toggler {
    color: #d0d2d6;
  }
}

.sidebar-toggler {
  font-size: 1.5rem;
}
</style>
