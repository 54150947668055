import { computed } from '@vue/composition-api'
import store from '@/store'

export default function useUserPersonalSidebar() {
  // Personal Sidebar
  const isUserPersonalSidebarOpen = computed({
    get: () => store.state.userPersonalSidebar.isUserPersonalSidebarOpen,
    set: val => {
      store.commit('userPersonalSidebar/UPDATE_USER_PERSONAL_SIDEBAR_OPEN', val)
    },
  })

  return {
    // Personal Sidebar
    isUserPersonalSidebarOpen,
  }
}
