export default {
  superadmin: [
    {
      header: "Akses Menu",
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'superadmin-dashboard',
    },
    // {
    //   title: 'Tahun Ajaran',
    //   icon: 'ti-calendar-stats',
    //   route: 'admin-study-years',
    // },
    {
      header: 'Master Data',
    },
    {
      title: 'Staff',
      icon: 'ti-user',
      route: 'staffs.index',
    },
    {
      title: 'Trainer',
      icon: 'ti-user',
      route: 'trainers.index',
    },
    {
      title: 'User',
      icon: 'ti-users',
      route: 'users.index',
    },
    {
      header: 'Menu Pelatihan',
    },
    {
      title: 'Pelatihan',
      icon: 'ti-archive',
      route: 'superadmin-trainings.index',
    },
    {
      title: 'Request Pelatihan',
      icon: 'ti-school',
      route: 'superadmin-training-requests.index',
    },
    {
      title: 'Transaksi',
      icon: 'ti-shopping-cart',
      route: 'superadmin-transactions.index',
    },
    {
      header: 'Pengaturan Web',
    },
    {
      title: 'Halaman',
      icon: 'ti-archive',
      route: 'superadmin-pages.index',
    },
    {
      title: 'Promo',
      icon: 'ti-shopping-cart',
      route: 'superadmin-promos.index',
    },
  ],
  tu: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'tu-dashboard',
    },
    {
      header: 'Master Data',
    },
    {
      title: 'Trainer',
      icon: 'ti-user',
      route: 'trainers.index',
    },
    {
      title: 'User',
      icon: 'ti-users',
      route: 'users.index',
    },
    {
      header: 'Menu Pelatihan',
    },
    {
      title: 'Pelatihan',
      icon: 'ti-school',
      route: 'tu-trainings.index',
    },
    {
      title: 'Request Pelatihan',
      icon: 'ti-school',
      route: 'tu-training-requests.index',
    },
  ],
  admin: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'staff-dashboard',
    },
    {
      header: 'Master Data',
    },
    {
      title: 'Trainer',
      icon: 'ti-user',
      route: 'trainers.index',
    },
    {
      title: 'User',
      icon: 'ti-users',
      route: 'users.index',
    },
    {
      header: 'Menu Pelatihan',
    },
    {
      title: 'Pelatihan',
      icon: 'ti-school',
      route: 'staff-trainings.index',
    },
    {
      title: 'Request Pelatihan',
      icon: 'ti-school',
      route: 'staff-training-requests.index',
    },
  ],
  trainer: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'trainer-dashboard',
    },
    {
      title: 'Pelatihan',
      icon: 'ti-school',
      route: 'trainer-trainings.index',
    }
  ],
  student: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Dashboard',
      icon: 'ti-home',
      route: 'user-dashboard',
    },
    {
      title: 'Pelatihan',
      icon: 'ti-school',
      route: 'user-trainings.index',
    },
    {
      title: 'Pembayaran',
      icon: 'ti-shopping-cart',
      route: 'user-transactions.index',
    },
    {
      title: 'Request Pelatihan',
      icon: 'ti-school',
      route: 'user-training-requests.index',
    },
    {
      title: 'Sertifikat',
      icon: 'ti-file-text',
      route: 'user-certificates.index',
    },
  ],
  guest: [
    {
      header: 'Akses Menu',
    },
    {
      title: 'Pelatihan',
      icon: 'ti-school',
      route: 'guest-trainings.index',
    },
    {
      title: 'Masuk',
      icon: 'ti-login',
      route: 'auth-login',
    }
  ],
}
