<template>
  <div
    class="customizer d-md-block"
    :class="{ open: isUserPersonalSidebarOpen }"
  >
    <!-- Toggler -->
    <!-- <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isUserPersonalSidebarOpen = !isUserPersonalSidebarOpen"
    >
      <feather-icon icon="ChevronLeftIcon" size="15" />
    </b-link> -->
    <!-- /Toggler -->

    <!-- Header -->
    <div
      class="customizer-section d-flex justify-content-between align-items-center"
    >
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isUserPersonalSidebarOpen = !isUserPersonalSidebarOpen"
      />
    </div>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
    >
      <div class="pt-2">
        <div class="d-flex flex-column align-items-center">
          <!-- Avatar -->
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            size="104px"
            rounded
          />

          <!-- Username and role -->
          <div class="my-1 text-center">
            <h4 class="mb-1">
              {{ userData.name }}
            </h4>
            <b-badge variant="light-secondary">
              {{ userData.role_name || '-' }}
            </b-badge>
          </div>
          <!-- <div class="d-flex flex-wrap py-1 border-bottom-1">
            <div class="d-flex px-1">
              <b-avatar rounded="sm" variant="light-primary" icon="">
                <i class="font-medium-2 ti ti-checkup-list" />
              </b-avatar>
              <div class="pl-1">
                <h6 class="mb-0 font-weight-bolder">21</h6>
                <span>Tugas</span>
              </div>
            </div>
            <div class="d-flex px-1">
              <b-avatar rounded="sm" variant="light-primary" icon="">
                <i class="font-medium-2 ti ti-briefcase" />
              </b-avatar>
              <div class="pl-1">
                <h6 class="mb-0 font-weight-bolder">8</h6>
                <span>Kelas Diambil</span>
              </div>
            </div>
          </div> -->

          <!-- User Detail -->
          <div class="d-flex flex-column mt-2 user-detail px-3">
            <div>
              <span class="text-muted pb-2">Biodata</span>
              <table>
                <tr>
                  <td class="font-weight-bolder">
                    Nama
                  </td>
                  <td class="px-1">
                    :
                  </td>
                  <td>{{ userData.name || '-' }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bolder">
                    Email
                  </td>
                  <td class="px-1">
                    :
                  </td>
                  <td>{{ userData.email || '-' }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bolder">
                    Status
                  </td>
                  <td class="px-1">
                    :
                  </td>
                  <td>
                    <b-badge
                      :variant="`light-${userData ? 'success' : 'danger'}`"
                    >
                      {{ userData ? 'Aktif' : 'Inactive' }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bolder">
                    No HP
                  </td>
                  <td class="px-1">
                    :
                  </td>
                  <td>{{ userData.profile ? userData.profile.phone : '-' }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bolder">
                    Alamat
                  </td>
                  <td class="px-1">
                    :
                  </td>
                  <td>{{ userData.profile ? userData.profile.address : '-' }}</td>
                </tr>
              </table>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <b-button
                :to="{ name: 'user.profile' }"
                variant="primary"
                @click="isUserPersonalSidebarOpen = !isUserPersonalSidebarOpen"
              >
                <i class="ti ti-edit" />
                Edit Profil
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="logout"
              >
                Keluar
                <i class="ti ti-arrow-right" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </vue-perfect-scrollbar>

    <!-- Header -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onMounted } from '@vue/composition-api'
import {
  BLink, BButton, BAvatar, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useUserPersonalSidebar from './useUserPersonalSidebar'

export default {
  components: {
    // BSV
    BLink,
    BAvatar,
    BButton,
    BBadge,

    // 3rd party
    // vSelect,
    VuePerfectScrollbar,
  },
  setup(_, context) {
    // Initiate
    const { isUserPersonalSidebarOpen } = useUserPersonalSidebar()
    const userData = ref({})
    const router = context.root.$router

    // Method
    function logout() {
      isUserPersonalSidebarOpen.value = !isUserPersonalSidebarOpen.value
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      router.push({name: 'auth-login' })
    }

    // On Mounted
    onMounted(() => {
      const user = JSON.parse(localStorage.getItem('userData'))
      user.role_name = user.roles ? user.roles.map(role => role.name).join(', ') : ''

      userData.value = user
    })

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Customizer
      avatarText,
      isUserPersonalSidebarOpen,
      perfectScrollbarSettings,
      userData,
      logout,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  // border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.user-detail {
  td {
    padding: 0.3rem 0;
    vertical-align: top;
    text-align: left;
  }
}

.border-bottom-1 {
  border-bottom: 1px solid #ebe9f1;
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.ps-customizer-area {
  height: calc(100% - 83px);
}
</style>
