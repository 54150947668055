<template>
  <li class="nav-item nav-search">
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search d-flex"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
      <span class="ml-1 text-muted">Cari Pelatihan ...</span>
    </a>

    <!-- Input -->
    <div
      class="search-input w-10"
      :class="{ open: showSearchBar }"
    >
      <div class="search-input-icon">
        <feather-icon
          icon="SearchIcon"
          size="21"
        />
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Cari Pelatihan"
        autofocus
        autocomplete="off"
        @keyup.esc="
          showSearchBar = false
          resetsearchQuery()
        "
        @keyup.enter="suggestionSelected"
        @blur.stop="
          showSearchBar = false
          blur()
        "
      />
      <div
        class="search-input-close"
        @click="
          showSearchBar = false
          resetsearchQuery()
        "
      >
        <feather-icon icon="XIcon" />
      </div>
    </div>
  </li>
</template>

<script>
import {
  BFormInput, BLink, BImg, BAvatar,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
import { title } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import { checkAuthorizeRole } from '@/auth/utils'
import searchAndBookmarkData from '../search-and-bookmark-data'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    VuePerfectScrollbar,
  },
  setup() {
    let showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const suggestionSelected = (grpName, suggestion) => {
      // If parameter is not provided => Use current selected
      if (!suggestion) {
        // If currentSelected value is -1 => No value/item is selected (Prevent Errors)
        /* eslint-disable no-use-before-define, no-param-reassign */
        if (currentSelected.value !== -1) {
          /* eslint-disable no-use-before-define, no-param-reassign */
          const [grpIndex, itemIndex] = currentSelected.value.split('.')
          grpName = Object.keys(filteredData.value)[grpIndex]
          suggestion = filteredData.value[grpName][itemIndex]
          /* eslint-enable */
        }
      }
      if (grpName === 'pages') router.push(suggestion.route).catch(() => {})
      // eslint-disable-next-line no-use-before-define
      resetsearchQuery()
      showSearchBar.value = false
    }

    const { searchQuery, resetsearchQuery, filteredData } = useAutoSuggest({
      data: searchAndBookmarkData,
      searchLimit: 4,
    })

    /* if (searchQuery) {
      showSearchBar = true
    } */

    watch(searchQuery, val => {
      if (router.currentRoute.name === checkAuthorizeRole('trainings.index') || router.currentRoute.name === 'guest-trainings.index') {
        store.commit('search/SET_SEARCH_QUERY', val)
      } else {
        if (val) {
          router.push({
            name: checkAuthorizeRole('trainings.index'),
          })
        }
        store.commit('search/SET_SEARCH_QUERY', val)
      }
    })

    const currentSelected = ref(-1)
    watch(filteredData, val => {
      if (!Object.values(val).some(obj => obj.length)) {
        currentSelected.value = -1
      } else {
        // Auto Select first item if it's not item-404
        let grpIndex = null

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, grpSuggestions] of Object.values(val).entries()) {
          if (grpSuggestions.length) {
            grpIndex = index
            break
          }
        }

        if (grpIndex !== null) currentSelected.value = `${grpIndex}.0`
      }
    })

    const blur = () => {
      setTimeout(()=> {
            resetsearchQuery()
          }, 200)
    }

    return {
      showSearchBar,
      perfectScrollbarSettings,
      searchAndBookmarkData,
      title,
      suggestionSelected,
      currentSelected,

      // AutoSuggest
      searchQuery,
      resetsearchQuery,
      filteredData,
      blur
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}

.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
