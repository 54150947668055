<template>
  <layout-vertical :nav-menu-items="navMenuItems">
    <router-view />
    <!-- <app-customizer slot="customizer" /> -->
    <user-personal-sidebar v-if="userRole != 'guest'" slot="user-personal-sidebar" />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import UserPersonalSidebar from '@core/layouts/components/user-personal-sidebar/UserPersonalSidebar.vue'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import store from '@/store'

export default {
  components: {
    // AppCustomizer,
    UserPersonalSidebar,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      userRole: 'guest',
    }
  },
  computed: {
    // Condition : Nav menu by role
    navMenuItems() {
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
      if (this.userRole.toLowerCase() === 'user') {
        return navMenuItems.student
      }
      if (this.userRole.toLowerCase() === 'trainer') {
        return navMenuItems.trainer
      }
      if (this.userRole.toLowerCase() === 'staff') {
        return navMenuItems.admin
      }
      if (this.userRole.toLowerCase() === 'tu') {
        return navMenuItems.tu
      }
      if (this.userRole.toLowerCase() === 'super admin') {
        return navMenuItems.superadmin
      }
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      return navMenuItems.guest
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.userRole = userData ? JSON.parse(localStorage.getItem('userData')).roles[0].name : 'guest'
  },
}
</script>
