<template>
  <b-navbar
    :toggleable="false"
    class="
      header-navbar
      navbar-expand
      d-md-none d-lg-none d-xl-none
      fixed-bottom
      navbar-shadow
      nav-mobile
    "
  >
      <div class="navbar-container d-flex content align-items-center">
        <b-navbar-nav class="nav nav-justified nav-link w-100">
          <b-nav-item :to="{ name: checkAuthorizeRole('dashboard') }" :active="$route.meta.navActiveLink == checkAuthorizeRole('dashboard')">
            <svg
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-house"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
              />
              <path
                fill-rule="evenodd"
                d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
              />
            </svg>
          </b-nav-item>
          <b-nav-item
            v-if="isAdmin()"
            :to="{ name: checkAuthorizeRole('letter-of-assignments.index') }" :active="$route.meta.navActiveLink == checkAuthorizeRole('letter-of-assignments.index')"
          >
            <svg
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-calendar-date"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"
              />
              <path
                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
              />
            </svg>
          </b-nav-item>
          <b-nav-item :to="{ name: checkAuthorizeRole('trainings.index') }" :active="$route.meta.navActiveLink == checkAuthorizeRole('trainings.index')">
            <svg
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-person"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5ZM8 8.46 1.758 5.965 8 3.052l6.242 2.913L8 8.46Z"
              />
              <path
                d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46l-3.892-1.556Z"
              />
            </svg>
          </b-nav-item>
          <b-nav-item :to="{ name: 'user.profile' }" :active="$route.meta.navActiveLink == 'user.profile'">
            <svg
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-person"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
              />
            </svg>
          </b-nav-item>
        </b-navbar-nav>
      </div>
  </b-navbar>
</template>

<script>
import { BNavbar, BNavbarNav, BNavItem, BNav } from "bootstrap-vue";
import { checkAuthorizeRole, isAdmin } from "@/auth/utils";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BNavbar,
    BNavbarNav,
    BNavItem,
    BNav,
  },
  props: {
  },
  methods: {
    checkAuthorizeRole,
    isAdmin
  },
  setup() {
    const { skin, navbarBackgroundColor } = useAppConfig();

    return {
      skin,
      navbarBackgroundColor,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
.nav-bg-bottom-light {
  background: #ffffff !important;
  box-shadow: -1px -5px 9px 1px rgba(212, 212, 212, 0.75);
  -webkit-box-shadow: -1px -5px 9px 1px rgba(212, 212, 212, 0.75);
  -moz-box-shadow: -1px -5px 9px 1px rgba(212, 212, 212, 0.75);
}
.nav-bg-bottom-dark {
  background: #161d31 !important;
  box-shadow: -1px -5px 9px 1px rgba(61, 63, 66, 0.75);
  -webkit-box-shadow: -1px -5px 9px 1px rgba(61, 63, 66, 0.75);
  -moz-box-shadow: -1px -5px 9px 1px rgba(61, 63, 66, 0.75);
}
</style>
